<template>
  <footer className="mt-20 mb-8 mx-4 border-t-2">
    <p class="text-sm pt-5 text-center">
      &copy; 2021 <a href="https://github.com/martinbilek/jonasovo">Jonasovo</a></p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>
